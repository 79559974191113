.change_password_dialog /deep/ .el-dialog {
  .el-dialog__body {
    .top {
      margin-bottom: 40px;
      font-size: 18px;

      .name {
        font-weight: bold;
        line-height: 1.44;
        color: $primary-purple;
        word-break: break-word;
        margin-bottom: 8px;
      }

      .number {
        font-weight: 300;
        line-height: 1.44;
        color: $primary-purple;
      }
    }

    .el-form-item:first-child {
      margin-bottom: 8px;
    }
    .forgotPassword {
      width: 100%;
      height: 22px;
      padding-top: 10px;

      .forgotPwd {
        @include rtl-sass-value(float, right, left);
        line-height: 1.64;
        color: $primary-purple;
        display: block;
        @include rtl-sass-value(text-align, right, left);
        text-decoration: underline;
        cursor: pointer;
        margin-bottom: 35px;
      }
    }
  }

  .new_password_group {
    margin-bottom: 80px;
  }
}
@media (max-width: 600px) {
  .new_password {
    /deep/ .el-input {
      margin-top: 15px;
    }
  }
}
@media (max-width: 550px) {
  .change_password_dialog /deep/ .el-dialog .el-dialog__body {
    .top {
      margin-bottom: 30px;

      .name,
      .number {
        font-size: 16px;
      }
    }
    .forgotPassword .forgotPwd {
      margin-bottom: 25px;
    }
  }
}
@media (max-width: 350px) {
  .new_password {
    /deep/ .el-input {
      margin-top: 45px;
    }
  }
}
