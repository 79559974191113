/* 点击小锁样式开始 */
.password_dialog {
  /deep/ .el-dialog .el-dialog__body {
    .top {
      margin-bottom: 45px;
      font-size: 18px;

      .name {
        font-weight: bold;
        line-height: 1.44;
        color: $primary-purple;
        word-break: break-word;
        margin-bottom: 8px;
      }

      .number {
        font-weight: 300;
        line-height: 1.44;
        color: $primary-purple;
      }
    }
  }
}

@media (max-width: 550px) {
  .password_dialog .el-dialog .el-dialog__body .top {
    margin-bottom: 40px;

    .name,
    .number {
      font-size: 16px;
    }
  }

  .form_button {
    text-align: center;

    .purple_button:first-child {
      margin-bottom: 32px;
    }
  }
}
