.home_common_dialog /deep/ .el-dialog__body {
  text-align: center;

  img {
    width: 114px;
    margin-bottom: 40px;
  }

  p {
    font-size: 18px;
    font-weight: 200;
    line-height: 1.64;
    color: $primary-purple;
    word-break: break-word;
  }
}

@media (max-width: 500px) {
  .home_common_dialog /deep/ .el-dialog__body img {
    width: 61px;
  }
}
