.change_leverage_dialog {
  /deep/ .el-form {
    .accNum {
      display: block;
      font-size: 18px;
      font-weight: bold;
      line-height: 1.44;
      color: $primary-purple;
      margin-bottom: 8px;
    }

    .text {
      display: block;
      font-size: 18px;
      font-weight: 300;
      line-height: 1.44;
      color: $primary-purple;
      margin-bottom: 50px;
    }

    .el-form-item {
      margin-bottom: 40px;
    }

    .el-form-item.info {
      color: $primary-purple;

      span {
        font-weight: bold;
      }

      p {
        line-height: 1.57;
        font-weight: 200;
        margin-bottom: 25px;
      }

      /deep/ .el-checkbox__label {
        font-size: 16px;
        font-weight: 200;
        line-height: 1.56;
        color: $primary-purple;
      }
    }
  }

  .checkDialog {
    margin: 20px 0;
    p {
      font-weight: 200;
      line-height: 1.64;
      color: $primary-purple;
    }
  }
}

@media (max-width: 550px) {
  .change_leverage_dialog {
    /deep/ .el-form {
      .accNum,
      .text {
        font-size: 16px;
      }

      .el-form-item {
        margin-bottom: 30px;
      }

      .text:not(.last) {
        margin-bottom: 32px;
      }

      .el-checkbox {
        line-height: 1.56;
      }

      .el-form-item__content .el-checkbox__label {
        display: inline;
      }
    }
  }
}
